import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Grid,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material"

const Footer = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Box sx={{ backgroundColor: "#002f8e", padding: "16px" }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography align={isSmall ? "center" : "left"}>
            <Link
              href="mailto:info@elbfoundation.org?cc=drmichaelj@mjmdglobal.com, &bcc=support@ornelastechnologies.com&subject=Mail from ELB Foundation"
              color="common.white"
            >
              info@elbfoundation.org
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography color="common.white" align={isSmall ? "center" : "right"}>
            © ELB Foundation. All rights reserved!
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

Footer.propTypes = {
  children: PropTypes.node,
}

export default Footer
