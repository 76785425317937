import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

const Center = ({ children, ...otherProps }) => {
  return (
    <Box display="flex" justifyContent="center" {...otherProps}>
      {children}
    </Box>
  )
}

Center.propTypes = {
  children: PropTypes.node,
}

export default Center
