import React from "react"
import PropTypes from "prop-types"

import { StyledLayout } from "./Layout.styled"
import { Navigation } from "../Navigation"
import { Footer } from "../Footer"

const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <Navigation />
      {children}
      <Footer />
    </StyledLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
