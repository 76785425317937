import cuid from "cuid"

export const navElements = [
  {
    id: cuid(),
    route: "/",
    title: "Home",
    anchor: "home",
    isActive: false,
    subNav: false,
  },
  {
    id: cuid(),
    route: "/about",
    title: "About",
    anchor: "about",
    isActive: false,
    subNav: false,
  },

  {
    id: cuid(),
    route: "/mission",
    title: "Mission",
    anchor: "mission",
    isActive: false,
    subNav: false,
  },

  {
    id: cuid(),
    route: "/scholarships",
    title: "Scholarships",
    anchor: "scholarships",
    isActive: false,
    subNav: false,
  },
  {
    id: cuid(),
    route: "/programs",
    title: "Become a Boulé Scholar",
    anchor: "programs",
    isActive: false,
    subNav: false,
  },
  {
    id: cuid(),
    route: "/partners",
    title: "Partners",
    anchor: "partners",
    isActive: false,
    subNav: false,
  },
]
