import { createTheme } from "@mui/material/styles"
import { lighten, darken } from "polished"

const headerStyles = {
  fontFamily: "'Oswald', sans-serif",
  color: "#fff",
  fontWeight: 400,
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      light: lighten(0.1, "#0038a7"),
      main: "#0038a7",
      dark: darken(0.1, "#0038a7"),
    },
    secondary: {
      light: lighten(0.1, "#2196f3"),
      main: "#2196f3",
      dark: darken(0.1, "#2196f3"),
    },
    alternate: {
      light: lighten(0.1, "#ffc908"),
      main: "#ffc908",
      dark: darken(0.1, "#ffc908"),
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif, 'Oswald', sans-serif",
    htmlFontSize: 20,
    fontSize: 18,
    h1: { ...headerStyles },
    h2: { ...headerStyles },
    h3: { ...headerStyles },
    h4: {
      ...headerStyles,
    },
    h5: {
      ...headerStyles,
    },
    h6: {
      ...headerStyles,
    },
  },
})

export default theme
