import React, { useState } from "react"
import { rem } from "polished"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"

import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Drawer,
  ListItem,
  List,
  Button,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import MenuIcon from "@mui/icons-material/Menu"

import { Center } from "../Layout"

import WebLogo from "../../images/logo-2018.svg"

import { StyledNavElement } from "./Navigation.styled"

// import { useMenuQuery } from "../../hooks/useMenuQuery"

import { navElements } from "./Navigation.api"

const Navigation = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))

  const [drawerIsOpen, setDrawerIsOpen] = useState(false)

  const openDrawer = () => {
    setDrawerIsOpen(true)
  }
  const handleClickMobile = _route => {
    navigate(_route)
    setDrawerIsOpen(false)
  }
  return (
    <>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <Box
            sx={{
              flexGrow: {
                xs: 1,
                md: 3,
              },
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                md: "flex-start",
              },
            }}
          >
            <Box
              component="img"
              src={WebLogo}
              alt="logo elb"
              sx={{ py: 2, width: isSmall ? rem(150) : rem(300) }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <Box>
              <Box display="flex" justifyContent="space-between">
                {navElements.map(({ id, route, title, isActive }) => (
                  <StyledNavElement
                    component={Link}
                    to={route}
                    key={id}
                    activeStyle={{
                      color: theme.palette.primary.main,
                      fontWeight: 600,
                      borderBottom: `2px solid ${theme.palette.alternate.main}`,
                    }}
                  >
                    {title}
                  </StyledNavElement>
                ))}
                <Button
                  variant="contained"
                  to="/donate"
                  component={Link}
                  color="secondary"
                  sx={{
                    color: "white",
                  }}
                >
                  <strong>Donate</strong>
                </Button>
              </Box>
              {/* <SocialLinks /> */}
            </Box>
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
              },
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="secondary"
              aria-label="open drawer"
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            {navElements.map(({ id, route, title, isActive }) => (
              <ListItem key={id}>
                <StyledNavElement
                  onClick={() => handleClickMobile(route)}
                  key={id}
                  activeStyle={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    borderBottom: `2px solid ${theme.palette.alternate.main}`,
                  }}
                >
                  {title}
                </StyledNavElement>
              </ListItem>
            ))}
            <ListItem>
              <Center>
                <Button
                  variant="contained"
                  onClick={() => handleClickMobile("/donate")}
                  color="secondary"
                  sx={{
                    color: "white",
                  }}
                >
                  <strong>Donate</strong>
                </Button>
              </Center>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  )
}

Navigation.propTypes = {
  children: PropTypes.node,
}

export default Navigation
