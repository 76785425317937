import React from "react"
import PropTypes from "prop-types"
import { Box, useTheme, useMediaQuery } from "@mui/material"

import Hr from "../../images/hr.png"
import HrAlt from "../../images/hr-alt.png"

import Center from "./Center"

const FancyHr = ({ alt }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Center my={4}>
      {alt ? (
        <Box
          component="img"
          src={HrAlt}
          sx={{
            width: isSmall ? "100%" : "inherit",
            display: isSmall ? "inline-block" : "inherit",
          }}
        />
      ) : (
        <Box
          component="img"
          src={Hr}
          sx={{
            width: isSmall ? "100%" : "inherit",
            display: isSmall ? "inline-block" : "inherit",
          }}
        />
      )}
    </Center>
  )
}

FancyHr.propTypes = {
  alt: PropTypes.bool,
}

export default FancyHr
